import http from "@/api/http";

export const addModifySolution = ( data ) => http.post( '/mgm/solution/addModify', data );

export const addModifyProduct = ( data ) => http.post( '/mgm/product/addModify', data );

export const addModifyCase = ( data ) => http.post( '/mgm/case/addModify', data );

export const pageSolution = ( data ) => http.post( `/mgm/solution/page`, data );

export const pageProduct = ( data ) => http.post( `/mgm/product/page`, data );

export const pageCase = ( data ) => http.post( `/mgm/case/page`, data );

export const login = ( data ) => http.post( '/sys/user/login', data );

export const addModifyBanner = ( data ) => http.post( '/mgm/banner/addModify', data );

export const delBanner = ( data ) => http.post( '/mgm/banner/delete', data );

export const delProduct = ( data ) => http.post( '/mgm/product/delete', data );

export const delCase = ( data ) => http.post( '/mgm/case/delete', data );

export const pageBanner = () => http.post( '/mgm/banner/page' );

// 获取新闻列表
export const getNewsPage = () => http.post( '/mgm/news/page' );
// 新闻 - 新闻 - 添加/修改
export const addModify = (data) => http.post( '/mgm/news/addModify', data );
// 删除 
export const delNews = ( data ) => http.post( '/mgm/news/delete', data );
// 获取新闻信息
export const getNews = ( data ) => http.post( '/mgm/news/', data );