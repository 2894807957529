<template>
  <el-card>
    <el-row>
      <el-button type="primary" @click="add">添加方案</el-button>
    </el-row>
    <el-table :data="data" border style="width: 100%;margin: 15px 0">

      <el-table-column align="center" prop="createdAt" label="创建时间"></el-table-column>

      <el-table-column align="center" prop="title" label="标题"></el-table-column>


      <el-table-column align="center" prop="analysisDepict" label="分析描述"></el-table-column>

      <el-table-column align="center" prop="programDepict" label="方案描述"></el-table-column>


      <el-table-column align="center" label="banner图" min-width="80" prop="banner">
        <template v-slot="scope">
          <el-image
              style="width: 50px; height:50px;margin: 7px 0;"
              :src="scope.row.banner"
              fit="cover"
              :preview-src-list="[scope.row.banner]"
          ></el-image>
        </template>
      </el-table-column>


      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" size="small" type="text">编辑</el-button>
          <!--          <el-button @click="del(scope.row.id)" size="small" type="text">删除</el-button>-->
        </template>
      </el-table-column>

    </el-table>

    <el-pagination
        style=" text-align: right;"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="[2,4,6,8,10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
    ></el-pagination>


    <!--    弹窗-->
    <el-dialog title="添加方案" :visible.sync="dialogFormVisible">
      <el-form :model="form">

        <el-form-item label="标题" :label-width="formLabelWidth">
          <el-input v-model="form.title"/>
        </el-form-item>

        <el-form-item label="banner图" :label-width="formLabelWidth">
          <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="bannerSuccess"
          >
            <img v-if="form.banner" :src="form.banner" class="banner">
            <i v-else class="el-icon-plus banner-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="分析描述" :label-width="formLabelWidth">
          <el-input v-model="form.analysisDepict"/>
        </el-form-item>

        <el-form-item label="分析列表" :label-width="formLabelWidth">
          <!--          <el-input v-model="form.analysisList"/>-->

          <div class="analysisList">
            <div v-for="(v,i) in form.analysisList" :key="i">
              <el-input v-model="v.title" placeholder="标题"/>
              <el-input v-model="v.txt" placeholder="内容"/>
            </div>
          </div>

        </el-form-item>

        <el-form-item label="方案描述" :label-width="formLabelWidth">
          <el-input v-model="form.programDepict"/>
        </el-form-item>

        <el-form-item label="方案列表" :label-width="formLabelWidth">
          <div class="programList">
            <div v-for="(v,i) in form.programList" :key="i">
              <el-upload
                  ref="program"
                  class="avatar-uploader"
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="(file)=>{return programListSuccess(file,i) }"
                  :data-index="i"
              >
                <img v-if="v.img" :src="v.img" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>

              <el-input v-model="v.title" placeholder="标题"/>
              <el-input v-model="v.txt" placeholder="内容"/>
            </div>

          </div>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addModifySolution">确 定</el-button>
      </div>
    </el-dialog>


  </el-card>

</template>

<script>

import {addModifySolution, pageSolution} from '@/api/sys';


export default {
  name: "solution",
  data() {
    return {
      total: 0,
      queryInfo: {
        // query: "",
        pageNum: 1,
        pageSize: 10,
      },
      data: [],
      dialogFormVisible: false,
      uploadUrl: process.env.VUE_APP_BASE_URL + '/file/oss',
      form: {
        id: null,
        title: '',
        banner: '',
        analysisDepict: '',
        analysisList: [
          {title: '', txt: ''},
          {title: '', txt: ''},
          {title: '', txt: ''},
        ],
        programDepict: '',
        programList: [
          {img: '', title: '', txt: ''},
          {img: '', title: '', txt: ''},
          {img: '', title: '', txt: ''},
          {img: '', title: '', txt: ''},
        ],
      },
      formLabelWidth: '100px',
      imageUrl: ''
    }
  },
  created() {
    this.pageSolution();
  },
  methods: {
    add() {
      this.form = {
        id: null,
        title: '',
        banner: '',
        analysisDepict: '',
        analysisList: [
          {title: '', txt: ''},
          {title: '', txt: ''},
          {title: '', txt: ''},
        ],
        programDepict: '',
        programList: [
          {img: '', title: '', txt: ''},
          {img: '', title: '', txt: ''},
          {img: '', title: '', txt: ''},
          {img: '', title: '', txt: ''},
        ],
      };
      this.dialogFormVisible = true
    },

    async addModifySolution() {
      const {data, code, msg} = await addModifySolution(this.form);
      if (code != 200) return this.$message.error(msg);
      this.$message.success(msg);
      this.dialogFormVisible = false;
      this.pageSolution();

    },

    async pageSolution() {
      const {data, code, msg,total} = await pageSolution(this.queryInfo);
      // data.programList = data.programList.map(r => {
      //   return {...r, ...{txt: JSON.stringify(r.txt)}}
      // });
      for (let dataVal of data) {
        dataVal.programList = dataVal.programList.map(r => {
          return {...r, ...{txt: JSON.stringify(r.txt)}}
        });
      }
      this.data = data;
      this.total=total

    },
    edit(scope) {
      this.form = scope;
      this.dialogFormVisible = true
    },

    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.pageSolution()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.pageSolution()
    },
    programListSuccess(file, index) {
      const {data, code, msg} = file;
      if (code != 200) return this.$message.error(msg);
      this.$message.success(msg);
      this.form.programList[index].img = data.url;
    },
    bannerSuccess(file) {
      const {data} = file;
      this.form.banner = data.url
    },

  }
}
</script>

<style lang="less" scoped>

/deep/ .el-dialog__body {
  padding: 10px 50px 0 0;
}


.analysisList {
  border: 1px solid #d7d5d5;
  padding: 10px;

  > div {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;

    > div {
      width: 40%;
    }
  }
}


.programList {
  border: 1px solid #d7d5d5;
  padding: 10px;

  > div {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    align-content: center;

    > div {
      margin: 0 15px;
      display: flex;
      align-items: center;
    }


  }
}


/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

/deep/ .banner-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 775px;
  height: 350px;
  line-height: 350px;
  text-align: center;
}

/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .banner {
  width: 775px;
  height: 350px;
  display: block;
}
</style>
